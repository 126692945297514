var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.scrollToTop()),expression:"scrollToTop()"}]})])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama agen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"agen_name"}},[_vm._v("Nama Agen "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama Agen"},model:{value:(_vm.model.agen_name),callback:function ($$v) {_vm.$set(_vm.model, "agen_name", $$v)},expression:"model.agen_name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama petugas (PIC)","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"pic_name"}},[_vm._v("Nama Petugas (PIC) "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama Petugas (PIC)"},model:{value:(_vm.model.pic_name),callback:function ($$v) {_vm.$set(_vm.model, "pic_name", $$v)},expression:"model.pic_name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nomor ponsel (PIC)","rules":'required|numeric|min:8|max:14'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"phone_number"}},[_vm._v("Nomor Ponsel (PIC) "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","type":"number","placeholder":"Masukkan Nomor Ponsel (PIC)"},model:{value:(_vm.model.phone_number),callback:function ($$v) {_vm.$set(_vm.model, "phone_number", $$v)},expression:"model.phone_number"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email kantor","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"email"}},[_vm._v("Email Kantor "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-email","type":"email","placeholder":"Masukkan Email Kantor"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"No rekening POSPAY","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"pgm_number"}},[_vm._v("No Rekening POSPAY "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","type":"number","placeholder":"Masukkan No Rekening POSPAY"},model:{value:(_vm.model.pgm_number),callback:function ($$v) {_vm.$set(_vm.model, "pgm_number", $$v)},expression:"model.pgm_number"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Alamat kantor","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"address"}},[_vm._v("Alamat Kantor "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-textarea',{attrs:{"id":"mc-name","rows":"3","placeholder":"Masukkan Alamat Kantor"},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"No PKS","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"pks_number"}},[_vm._v(" No PKS "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","type":"text","placeholder":"Masukkan No PKS"},model:{value:(_vm.model.pks_number),callback:function ($$v) {_vm.$set(_vm.model, "pks_number", $$v)},expression:"model.pks_number"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"status"}},[_vm._v("Status "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":_vm.statusList,"value-field":"value","text-field":"label"},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('label',[_vm._v("Masa Berlaku PKS "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('label',[_vm._v("NPWP "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Tanggal mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('date-picker',{attrs:{"lang":_vm.lang,"format":_vm.formats,"firstDayOfWeek":1,"placeholder":"Tanggal mulai","name":"start_date","type":"date","value-type":"format","default-value":new Date()},model:{value:(_vm.model.start_date),callback:function ($$v) {_vm.$set(_vm.model, "start_date", $$v)},expression:"model.start_date"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Tanggal akhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('date-picker',{attrs:{"lang":_vm.lang,"format":_vm.formats,"name":"end_date","placeholder":"Tanggal akhir","type":"date","value-type":"format","disabled-date":_vm.disabledBeforeStartDate},model:{value:(_vm.model.end_date),callback:function ($$v) {_vm.$set(_vm.model, "end_date", $$v)},expression:"model.end_date"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"npwp","rules":"required|min:20|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###.#-###.###'),expression:"'##.###.###.#-###.###'"}],attrs:{"id":"npwp","placeholder":"Masukan NPWP"},model:{value:(_vm.model.npwp),callback:function ($$v) {_vm.$set(_vm.model, "npwp", $$v)},expression:"model.npwp"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v(" Simpan "),_c('feather-icon',{attrs:{"icon":"LoaderIcon"}})],1):_c('div',[_vm._v("Simpan")])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'mitra' }}},[_vm._v(" Batal ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }